import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Grid, Typography } from '@mui/material';
import WCTTextField from '@components/input/text-field';
import { PrimaryButton } from '@components/buttons';
import { InputSkeleton } from '@components/skeletons';
import { useAppTr } from '@i18n/use-app-tr';
import WCTSelectField from '@components/input/select-field';
import { Divider } from '@components/layout-util-components/divider';
import useCompanyInformation from '@pages/app/company-profile/company-information-hook';
import WCTAddressField from '@components/input/address-field';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import SubpageTitle from '@components/menu-items/subpage-title';
import RefreshCancelButton from '@components/buttons/refresh-cancel-button';
import { Gap } from '@components/layout-util-components/gap';
import { ColumnForm } from '@components/layout-util-components/column';
import config from 'src/config';
import ImageField from '@components/input/image-field/image-field';
import WCTSwitchField from '@components/input/switch-field';

function CompanyInformationPage() {
  const { t } = useAppTr('companyInformation');

  const {
    name,
    sector,
    businessArea,
    address,
    countryOptions,
    sectorOptions,
    businessAreaOptions,
    currencyOptions,
    defaultCurrency,
    canSubmit,
    submit,
    isLoading,
    isSubmitting,
    uploadedFiles,
    useLogo,
    defaultSatelliteMap,
    defaultShowMapTerrain,
    defaultShowMapLabel,
  } = useCompanyInformation();

  const { showConfirmationModal } = useUiPopup();

  const onSubmit = async () => {
    const dirtyCount = [
      name.isDirty,
      sector.isDirty,
      businessArea.isDirty,
      address.isDirty,
    ].filter((isDirty) => isDirty).length;

    let shouldContinue: boolean | undefined;
    if (dirtyCount > 1) {
      shouldContinue = await showConfirmationModal({
        title: t('updateModals.generic.title'),
        message: t('updateModals.generic.message'),
        yesButtonLabel: t('updateModals.generic.yesButtonLabel'),
        noButtonLabel: t('updateModals.generic.noButtonLabel'),
      });
    } else if (name.isDirty) {
      shouldContinue = await showConfirmationModal({
        title: t('updateModals.name.title'),
        message: t('updateModals.name.message'),
        yesButtonLabel: t('updateModals.name.yesButtonLabel'),
        noButtonLabel: t('updateModals.name.noButtonLabel'),
      });
    } else if (sector.isDirty) {
      shouldContinue = await showConfirmationModal({
        title: t('updateModals.sector.title'),
        message: t('updateModals.sector.message'),
        yesButtonLabel: t('updateModals.sector.yesButtonLabel'),
        noButtonLabel: t('updateModals.sector.noButtonLabel'),
      });
    } else if (businessArea.isDirty) {
      shouldContinue = await showConfirmationModal({
        title: t('updateModals.businessArea.title'),
        message: t('updateModals.businessArea.message'),
        yesButtonLabel: t('updateModals.businessArea.yesButtonLabel'),
        noButtonLabel: t('updateModals.businessArea.noButtonLabel'),
      });
    } else if (address.isDirty) {
      shouldContinue = await showConfirmationModal({
        title: t('updateModals.address.title'),
        message: t('updateModals.address.message'),
        yesButtonLabel: t('updateModals.address.yesButtonLabel'),
        noButtonLabel: t('updateModals.address.noButtonLabel'),
      });
    } else {
      shouldContinue = true;
    }

    if (shouldContinue) {
      await submit();
    }
  };

  return (
    <ColumnForm
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <SubpageTitle breadCrumb="Company Settings" title="Company Information" />
      <Gap size={32} />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="h3">{t('name.title')}</Typography>
          <Typography variant="body2">{t('name.message')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTTextField
              name="name"
              label={t('name.label')}
              value={name.value}
              error={name.error}
              onChange={name.set}
              required
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="h3">Default map settings</Typography>
          <Typography variant="body2">
            Set the default map settings to customize how you like to view maps
          </Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSwitchField
              label="Display as satellite"
              name="DefaultSatellite"
              value={defaultSatelliteMap.value}
              error={defaultSatelliteMap.error}
              onChange={defaultSatelliteMap.set}
            />
          )}
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <>
              {defaultSatelliteMap.value === true ? (
                <WCTSwitchField
                  label="Display labels"
                  name="DefaultLabels"
                  value={defaultShowMapLabel.value}
                  error={defaultShowMapLabel.error}
                  onChange={defaultShowMapLabel.set}
                />
              ) : (
                <WCTSwitchField
                  label="Display terrain"
                  name="DefaultLabels"
                  value={defaultShowMapTerrain.value}
                  error={defaultShowMapTerrain.error}
                  onChange={defaultShowMapTerrain.set}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="h3">Logo</Typography>
          <Typography variant="body2">Upload a company logo</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <ImageField
              uploadUrl={`${config.apiUrl}/api/company/logo`}
              previewUrl={
                uploadedFiles.value
                  ? `${config.apiUrl}/api/company/logo/${uploadedFiles.value}`
                  : undefined
              }
              value={uploadedFiles.value}
              onChange={uploadedFiles.set}
              error={uploadedFiles.error}
              uploadMessage="Drag a file to upload a logo"
              extraControls={
                <>
                  <WCTSwitchField
                    name="useLogo"
                    label="Use logo"
                    value={useLogo.value}
                    onChange={useLogo.set}
                    error={useLogo.error}
                  />
                </>
              }
            />
          )}
        </Grid>
      </Grid>

      <Divider addMargin />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="h3">{t('type.title')}</Typography>
          <Typography variant="body2">{t('type.message')}</Typography>
        </Grid>
        <Grid container item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              id="sector"
              name="sector"
              label={t('type.sectorLabel')}
              options={sectorOptions!}
              value={sector.value}
              error={sector.error}
              onChange={sector.set}
              required
            />
          )}
          <Gap size={32} />
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              name="businessArea"
              id="business-area"
              label={t('type.areaLabel')}
              options={businessAreaOptions!}
              value={businessArea.value}
              error={businessArea.error}
              onChange={businessArea.set}
              required
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="h3">{t('currency.title')}</Typography>
          <Typography variant="body2">
            Choose the currency your RCA reports will use
          </Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              name="currency"
              id="currency"
              label={t('currency.currencyLabel')}
              options={currencyOptions!}
              value={defaultCurrency.value}
              error={defaultCurrency.error}
              onChange={defaultCurrency.set}
              required
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="h3">{t('address.title')}</Typography>
          <Typography variant="body2">
            This is your company's primary administrative address
          </Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTAddressField
              id="address"
              countryOptions={countryOptions!}
              value={address.value}
              errors={address.errors}
              onChange={address.set}
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid item container justifyContent="flex-end" alignItems="center">
        <RefreshCancelButton />
        <Gap size={20} />
        <PrimaryButton
          disabled={!canSubmit}
          isBusy={isSubmitting}
          type="submit"
        >
          {t('updateButtonLabel')}
        </PrimaryButton>
      </Grid>
    </ColumnForm>
  );
}

export default requireAuth(CompanyInformationPage);
